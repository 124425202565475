<h2 mat-dialog-title id="dialog-title">
  {{ data.title }}
</h2>
<div *ngIf="data.message" mat-dialog-content>
  <p class="dialog-message" [innerHTML]="data.message | safeHtml"></p>
</div>
<div mat-dialog-actions>
  <button (click)="close()" aria-label="close">
    {{ data.acceptButton || 'OK' }}
  </button>
</div>
