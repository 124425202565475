import { Component, ChangeDetectionStrategy, Input, forwardRef, HostBinding } from '@angular/core';
import { EventConsentStatement } from '@shared/interfaces';
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'sd-consent-checkbox',
  templateUrl: './consent-checkbox.component.html',
  styleUrls: ['./consent-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return ConsentCheckboxComponent;
      }),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => {
        return ConsentCheckboxComponent;
      }),
      multi: true,
    },
  ],
})
export class ConsentCheckboxComponent implements ControlValueAccessor, Validator {
  private state: boolean = false;

  @HostBinding('class.error')
  isError: boolean = false;

  @Input()
  consentStatement: EventConsentStatement = {} as EventConsentStatement;

  private onChange: any = () => {/* intentional */};
  private onTouched: any = () => {/* intentional */};
  private onValidationChange: any = () => {/* intentional */};

  writeValue (value: boolean): void {
    this.state = value;
  }

  registerOnChange (onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched (onTouched: any): void {
    this.onTouched = onTouched;
  }

  registerOnValidatorChange (fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate (form: FormGroup): ValidationErrors | null {
    if (this.consentStatement.required && this.state !== true) {
      return { required: true };
    }
    return null;
  }

  onStateChange (checked: boolean): void {
    this.state = checked;
    this.onChange({
      id: this.consentStatement.id,
      value: this.state,
    });
  }

  showErrorMessage (isError: boolean): void {
    this.isError = isError;
  }
}
