import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Constructor } from './mixin-boilerplate';

export interface CanUnsubscribe {
  subscribe: (subscription: Subscription) => void;
  subscription: Subscription;
}

export type CanUnsubscribeClass = Constructor<CanUnsubscribe>;

/**
 * Mixin to augment a component for unsubscribing
 * from all observables when onDestroy firing.
 */
export function mixinUnsubscribe<T extends Constructor<{}>> (base: T): CanUnsubscribeClass & T {
  return class extends base implements OnDestroy {
    private subscriptions = new Subscription();

    constructor (...args: any[]) {
      super(...args);
    }

    /**
     * Adds a subscription to subscription list through setter.
     *
     * @param {Subscription} subscription
     * @return {void}
     */
    set subscription (subscription: Subscription) {
      this.subscriptions.add(subscription);
    }

    /**
     * Adds a subscription to subscription list.
     *
     * @param {Subscription} subscription
     * @return {void}
     */
    subscribe (subscription: Subscription): void {
      this.subscriptions.add(subscription);
    }

    /**
     * Destroys all RxJs subscriptions.
     *
     * @return {void}
     */
    ngOnDestroy (): void {
      this.subscriptions.unsubscribe();
    }
  };
}
