export enum SpeedQuality {
  high = 'High',
  low = 'low',
  none = 'None',
}

export enum SpeedtestState {
  addingSettings = 0,
  addingServers = 1,
  serverSelectionDone = 2,
  testRunning = 3,
  done = 4,
}

export enum TestState {
  notStarted = -1,
  starting = 0,
  downloadTest = 1,
  pingJitterTest = 2,
  uploadTest = 3,
  finished = 4,
  abort = 5,
}
