import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init, instrumentAngularRouting } from '@sentry/angular';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import * as Environment from '@env/environment';

import './libs';

if (Environment.stub && environment.production) {
  init({
    dsn: 'https://503af187aa7b41eeae4aab4a67f281df@o415314.ingest.sentry.io/5706548',
    release: environment.buildName,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', environment.apiUrl],
        routingInstrumentation: instrumentAngularRouting,
      }),
    ],
    environment: environment.envName,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
    ],
    denyUrls: [
      // Speedtest
      /.*\/speedtest_worker\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  });

  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((error) => {
    console.error(error);
  });
