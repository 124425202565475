<div
  (sdFormControlErrorChanged)="showErrorMessageWithTimeout($event)"
  [sdFormControlSelector]="'input'"
>
  <ng-content></ng-content>
</div>
<div
  *ngIf="isError"
  class="error-message"
  role="alert"
  [innerHTML]="errorMessage | mapFn : lineBreaks | safeHtml"
>
</div>
