<h2 mat-dialog-title id="dialog-title">
  You will be transferred to the Shindig app to participate in the event.
</h2>
<div mat-dialog-actions>
  <button *ngIf="isIOS || isIPadOS" (click)="iOSAppLaunch()" aria-label="close">
    OK
  </button>
  <button *ngIf="isAndroid" (click)="androidAppLaunch()" aria-label="close">
    OK
  </button>
</div>
