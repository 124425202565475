/**
 * When Angular add possibility to bind directives to host elements
 * @link https://angular.io/guide/roadmap#support-adding-directives-to-host-elements
 * this component could be improved to listen FormControlErrorDirective inside the component
 * and call the markFieldsAsError() method to add reaction for children fields when
 * parent was set as Dirty or Touched
 *
 * Now we need to do the following:
 * ```
 * <sd-entry-fields
 *   #entryFields
 *   (sdFormControlErrorChanged)="entryFields.toggleFieldsTouched($event)"
 * ></sd-entry-fields>
 * ```
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  HostBinding,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { EntryField, EventEntryField } from '@shared/interfaces';
import { Base, CanUnsubscribeClass, mixinUnsubscribe } from '@shared/mixins';
import { noWhitespaceValidator } from '@shared/validators';

// Boilerplate for applying mixins to FormFieldComponent.
const MixinBasedClass: CanUnsubscribeClass = mixinUnsubscribe(Base);

@Component({
  selector: 'sd-entry-fields',
  templateUrl: './entry-fields.component.html',
  styleUrls: ['./entry-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {return EntryFieldsComponent;}),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => {return EntryFieldsComponent;}),
      multi: true,
    },
  ],
})
export class EntryFieldsComponent extends MixinBasedClass
  implements OnInit, ControlValueAccessor, Validator
{
  @Input()
  entryFields: EventEntryField[] = [];

  @Input()
  isHidden = false;

  @Input()
  set formData (data: EntryField[]) {
    if (_.isNil(this.form) === true) {
      this.formInit();
    }

    if (_.isNil(data) === true) {
      this.formReset();
      return;
    }

    this.form.patchValue(data ?? []);
  }

  form: FormArray;

  entryFieldsMap: { [id: number]: string } = {};

  @HostBinding('class.empty')
  private isEntryFieldsEmpty: boolean;

  private onChange: any = () => {/* intentional */};
  private onTouched: any = () => {/* intentional */};
  private onValidationChange: any = () => {/* intentional */};

  constructor (
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit (): void {
    this.formInit();
    this.isEntryFieldsEmpty = !this.entryFields?.length;

    this.subscription = this.form.valueChanges.subscribe(() => {
      this.formEmit();
    });
  }

  private formInit (): void {
    if (_.isNil(this.form) === false) {
      return;
    }

    this.generateEntryFieldsMap();
    this.form = this.fb.array(this.generateFormGroups());
  }

  private formReset (): void {
    const entryFields = this.getEntryFields(this.form)
      .map((entryField) => {
        return {
          id: entryField.get('id')?.value,
          value: '',
        };
      });

    this.form.reset(entryFields, { emitEvent: true });
  }

  private formEmit (): void {
    this.onValidationChange();
    this.onChange(this.form.value);
  }

  private generateEntryFieldsMap (): void {
    this.entryFields.forEach(({ id, name }) => {
      this.entryFieldsMap[id] = name;
    });
  }

  private generateFormGroups (): FormGroup[] {
    return this.entryFields.map(({ id, type }) => {
      const validator = type === 'required'
        ? [Validators.required, noWhitespaceValidator]
        : Validators.nullValidator;
      return this.fb.group({
        id: [id],
        value: ['', validator],
      });
    });
  }

  writeValue (data: EntryField[]): void {
    this.formData = data;
  }

  registerOnChange (fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched (fn: any): void {
    this.onTouched = fn;
  }

  validate (form: FormGroup): ValidationErrors | null {
    if (this.form.invalid) {
      return this.getEntryFields(this.form)
        .map((formGroup) => {
          return formGroup.get('value')?.errors;
        });
    }
    return null;
  }

  registerOnValidatorChange (fn: () => void): void {
    this.onValidationChange = fn;
  }

  toggleFieldsTouched (isTouched: boolean): void {
    return isTouched ? this.form.markAllAsTouched() : this.form.markAsUntouched();
  }

  getEntryFields (form: FormArray): FormGroup[] {
    return form.controls as FormGroup[];
  }

  toInputName ([value, i]: [string, number]): string {
    return `name_${value?.replace(/\s/g, '_')}${i}`;
  }
}
