export type Constructor<T> = new(...args: any[]) => T;

/**
 * Empty Base class to use it for construct mixins
 */
export class Base {
  constructor () {/* intentional */}
}

/**
 * Es6 example
 * const compose = (f, g) => (...args) => f(g(...args));
 */
const compose = (f, g) => {
  return (...args) => {
    return f(g(...args));
  };
};

/**
 * Es6 example
 * const applyMixins = (...fns) => fns.reduce(compose);
 */
export const applyMixins = (...fns: Function[]) => {
  return fns.reduce(compose);
};

/**
 * ### How to apply mixins to component
 *
 * First create several mixins:
 *
 * The Delete Mixin
 *
 * ```ts
 * export interface CanDelete {
 *   delete: (someValue: any) => void;
 * }
 *
 * export type CanDeleteClass = Constructor<CanDelete>;
 *
 * export function mixinDelete<T extends Constructor<{}>> (base: T): CanDeleteClass & T {
 *   return class extends base {
 *     constructor(...args: any[]) {
 *       super(...args);
 *     }
 *
 *     delete(someValue: any): void {
 *       // logic to remove someValue
 *     }
 *   }
 * }
 * ```
 *
 * The Like Mixin
 *
 * ```ts
 * export interface CanLike {
 *   like: (someValue: any) => void;
 * }
 *
 * export type CanLikeClass = Constructor<CanLike>;
 *
 * export function mixinLike<T extends Constructor<{}>> (base: T): CanLikeClass & T {
 *   return class extends base {
 *     constructor(...args: any[]) {
 *       super(...args);
 *     }
 *
 *     like(someValue: any): void {
 *       // logic to like someValue
 *     }
 *   }
 * }
 * ```
 *
 * Creating the implementation component: SimpleComponent
 *
 * ```ts
 * const MixinBasedClass: CanDeleteClass & CanLikeClass = mixinDelete(mixinLike(Base));
 * ```
 *
 * or we can use the applyMixins function to compose our mixins:
 *
 * ```ts
 * const MixinBasedClass: CanDeleteClass & CanLikeClass = applyMixins(mixinDelete, mixinLike)(Base);
 * ```
 *
 * Once created the base component composed with the mixins, we can extend the new component:
 *
 * ```ts
 * @Component({
 *   selector: 'sd-simple-component',
 *   templateUrl: './simple.component.html',
 *   styleUrls: ['./simple.component.scss'],
 * })
 * export class SimpleComponent extends MixinBasedClass {
 *   constructor () {
 *     super();
 *   }
 * }
 * ```
 *
 */
