import { Component, ChangeDetectionStrategy, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from '../../interfaces';

@Component({
  selector: 'sd-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private mdDialogRef: MatDialogRef<ConfirmComponent>,
  ) { }

  close (value: boolean): void {
    this.mdDialogRef.close(value);
  }

  cancel (): void {
    this.close(false);
  }

  confirm (): void {
    this.close(true);
  }

  @HostListener('keydown.esc')
  onEsc (): void {
    this.close(false);
  }
}
