import { Injectable } from '@angular/core';
import { DateTime, Interval, Settings } from 'luxon';

@Injectable()
export class EventDatetimeService {
  constructor () {
    Settings.defaultLocale = 'en-US';
  }

  /**
   * Returns date in human-readable format.
   *
   * @param  {Date} startDate
   * @param  {Date} endDate
   * @param  {boolean} useLobbyFormat - if set to true, return only the starting date.
   * @return {string}
   */
  getDateInFormat (startDate: Date, endDate: Date, useLobbyFormat?: boolean): string {
    const mCurrentDate = DateTime.now();
    const mStartDate = DateTime.fromJSDate(startDate);
    const mEndDate = DateTime.fromJSDate(endDate);

    if (mCurrentDate >= mStartDate && mCurrentDate <= mEndDate) {
      return 'Now';
    }

    const startDateInFormat = this.getDateInFormatWithoutTime(startDate);

    if (useLobbyFormat) {
      const startTimeInFormat = this.getDateInTimeFormat(startDate, true);
      const zoneInFormat = this.getZoneInFormat();

      return `Opens ${startDateInFormat}, ${startTimeInFormat} (${zoneInFormat})`;
    }

    if (mStartDate.hasSame(mEndDate, 'day')) {
      const startTimeInFormat = this.getDateInTimeFormat(startDate, false);
      const endTimeInFormat = this.getDateInTimeFormat(endDate, true);
      const zoneInFormat = this.getZoneInFormat();

      return `${startDateInFormat}, ${startTimeInFormat} - ${endTimeInFormat} (${zoneInFormat})`;
    }

    const endDateInFormat = this.getDateInFormatWithoutTime(endDate);
    return `${startDateInFormat} - ${endDateInFormat}`;
  }

  /**
   * Returns current time zone.
   *
   * @return {string}
   */
  getZoneInFormat (): string {
    const zoneAbbr = DateTime.local().toFormat('ZZZZ');

    const zoneIsAGMTOffset = _.isNaN(+zoneAbbr) === false;
    return zoneIsAGMTOffset ? `GMT${zoneAbbr}` : zoneAbbr;
  }

  /**
   * Returns date in time format.
   *
   * @param  {Date} date
   * @param  {boolean} useAMPM
   * @return {string}
   */
  getDateInTimeFormat (date: Date, useAMPM: boolean): string {
    const mDate = DateTime.fromJSDate(date);

    const minutesAreVisible = mDate.toFormat('mm') !== '00';
    const ampmFormat = useAMPM ? `a` : ``;
    const timeFormat = minutesAreVisible ? `h:mm ${ampmFormat}` : `h ${ampmFormat}`;

    return mDate.toFormat(timeFormat);
  }

  /**
   * Returns date in format without time.
   *
   * @param  {Date} date
   * @return {string}
   */
  getDateInFormatWithoutTime (date: Date): string {
    const mDate = DateTime.fromJSDate(date);
    const mCurrentDate = DateTime.now();

    // Event will be started after 11 month
    const monthDiff = mDate.diff(mCurrentDate, 'month');
    if (monthDiff.months >= 11) {
      return mDate.toFormat(`MMMM d, y`);
    }

    // Day will start in 2-3 days from the current date
    const mStartOfThirdDayAfterCurrentDay = mCurrentDate.plus({ days: 2 }).set({ hour: 0, minute: 0 });
    const mEndOfFourthDayAfterCurrentDay = mCurrentDate.plus({ days: 3 }).set({ hour: 23, minute: 59 });
    const interval = Interval.fromDateTimes(mStartOfThirdDayAfterCurrentDay, mEndOfFourthDayAfterCurrentDay);

    if (interval.contains(mDate)) {
      const dateFormatWithWeekday = mDate.toFormat(`cccc, MMMM d`);
      return `This ${dateFormatWithWeekday}`;
    }

    const dateFormat = mDate.toFormat(`MMMM d`);

    // Event will be started today
    const dateIsToday = mCurrentDate.hasSame(mDate, 'day');
    if (dateIsToday) {
      return `Today, ${dateFormat}`;
    }

    // Event will be started tomorrow
    const mTomorrowDate = mCurrentDate.plus({ days: 1 });
    const dateIsTomorrow = mTomorrowDate.hasSame(mDate, 'day');
    if (dateIsTomorrow) {
      return `Tomorrow, ${dateFormat}`;
    }

    return dateFormat;
  }

  /**
   * Returns event duration dates in a human-readable format.
   *
   * @param  {Date} startDate
   * @param  {Date} endDate
   * @return {string}
   */
  eventDurationToString (startDate: Date, endDate: Date): string {
    const dtStartDate = DateTime.fromJSDate(startDate);
    const dtEndDate = DateTime.fromJSDate(endDate);
    const iEventDuration = Interval.fromDateTimes(dtStartDate, dtEndDate);

    // helper
    const iTodayTomorrow = Interval.after(DateTime.now().startOf('day'), { day: 2 });
    const getTodayTomorrow = (date: DateTime): string => {
      return iTodayTomorrow.contains(date)
        ? _.capitalize(date.toRelativeCalendar())
        : '';
    };

    // helper
    const concat = (parts: string[], separator = ', '): string => {
      return parts.filter(Boolean).join(separator);
    };

    const isOneDayEvent = iEventDuration.hasSame('day');
    const isEventOpen = iEventDuration.contains(DateTime.now());

    if (isEventOpen && isOneDayEvent) {
      const time = dtEndDate.toFormat('t (ZZZZ)');
      return `Now - ${time}`;
    }

    if (isEventOpen) {
      const prefix = getTodayTomorrow(dtEndDate);
      const month = dtEndDate.toFormat('LLLL d');
      return 'Now - ' + concat([prefix, month]);
    }

    if (isOneDayEvent) {
      const time = iEventDuration
        .toFormat('t') // returns string like '12:30 PM (EST) – 3:50 PM (EST)'
        .replace(/(.+)( am| pm)(.+)( am| pm)/i, (match, g1, g2, g3, g4) => {
          if (g2 === g4) { // if both times contains AM or PM ...
            return [g1, g3, g4].join(''); // ... return only the last one
          }
          return match; // ... return original string
        });

      const prefix = getTodayTomorrow(dtStartDate);
      const month = dtStartDate.toFormat('LLLL d');
      const offset = dtStartDate.toFormat(' (ZZZZ)');

      return concat([prefix, month, time]) + offset;
    }

    const startPrefix = getTodayTomorrow(dtStartDate);
    const startMonth = dtStartDate.toFormat('LLLL d');
    const startPart = concat([startPrefix, startMonth]);

    const endPrefix = getTodayTomorrow(dtEndDate);
    const endMonth = dtEndDate.toFormat('LLLL d');
    const endPart = concat([endPrefix, endMonth]);

    return concat([startPart, endPart], ' - ');
  }
}
