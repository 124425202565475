import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

interface Dimensions {
  width: number;
  height: number;
}

interface StyleProperties {
  ratio: number;
  width: number;
  maxWidth: number;
}

const HEADER_MAX_WIDTH = 1200;

@Component({
  selector: 'sd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  private bannerSizes: Dimensions;
  private logoSizes: Dimensions;

  bannerProperties: StyleProperties;
  logoProperties: StyleProperties;

  @Input()
  @HostBinding('class.has-banner')
  banner?: string | null;

  @Input()
  @HostBinding('class.has-logo')
  logo?: string | null;

  isShowHeader (): boolean {
    return !!this.banner;
  }

  onLoad (
    { naturalWidth, naturalHeight }: HTMLImageElement,
    type: 'banner' | 'logo',
  ): void {
    if (type === 'banner') {
      this.bannerSizes = {
        width: naturalWidth,
        height: naturalHeight,
      };
    }

    if (type === 'logo') {
      this.logoSizes = {
        width: naturalWidth,
        height: naturalHeight,
      };
    }

    this.setStyleProperties(this.bannerSizes, this.logoSizes);
  }

  /**
   * Finds the banner image ratio by its width and height
   * and calculates how much screen space it can take for render.
   * The same operation does with the logo image.
   * All results are binds to CSS variables which uses in the layout.
   *
   * @param {Dimensions} bannerSize
   * @param {Dimensions} logoSize
   * @private
   */
  private setStyleProperties (
    bannerSize: Dimensions,
    logoSize: Dimensions,
  ): void {
    if (_.isNil(bannerSize) || _.isNil(logoSize)) {
      return;
    }

    const resizedLogo = this.resizeByHeight(logoSize, bannerSize.height);
    const ratio = HEADER_MAX_WIDTH / (bannerSize.width + resizedLogo.width);

    const bannerMaxWidth = ratio * bannerSize.width;
    const logoMaxWidth = ratio * resizedLogo.width;

    this.bannerProperties = {
      ratio: bannerSize.width / bannerSize.height,
      width: bannerMaxWidth / HEADER_MAX_WIDTH * 100,
      maxWidth: bannerMaxWidth,
    };
    this.logoProperties = {
      ratio: resizedLogo.width / resizedLogo.height,
      width: logoMaxWidth / HEADER_MAX_WIDTH * 100,
      maxWidth: logoMaxWidth,
    };
  }

  private resizeByHeight (element: Dimensions, newHeight: number): Dimensions {
    const ratio = newHeight / element.height;
    return {
      width: element.width * ratio,
      height: element.height * ratio,
    };
  }
}
