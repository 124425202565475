import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck, tap } from 'rxjs/operators';
import { LOCAL_STORAGE, WINDOW } from '../tokens';
import { FullPageLocalStorage } from '@shared/interfaces/full-page.interface';
import { LS_FULL_EVENT_DATA_KEY } from '@shared/constants';

@Injectable()
export class FullEventService {
  constructor (
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(WINDOW) private window: Window,
  ) { }

  /**
   * Stores data for trying to Sign Up user in an event if it is crowded
   * The Sign Up endpoint is '<Events API Service>/api/v3/event-session/signup'
   *
   * @param {Object} data
   */
  setSignupData (data: any): void {
    this.localStorage.setItem(LS_FULL_EVENT_DATA_KEY, JSON.stringify({ data }));
  }

  /**
   * Stores the URL through which user will Sign Up in an event if it is crowded
   *
   * @param {String} url
   */
  setSignupUrl (url: string): void {
    this.localStorage.setItem(LS_FULL_EVENT_DATA_KEY, JSON.stringify({ redirectUrl: url }));
  }

  /**
   * Checks if required params were stored
   *
   * @return {boolean}
   */
  canSignup (): boolean {
    return Boolean(this.localStorage.getItem(LS_FULL_EVENT_DATA_KEY));
  }

  /**
   * Gets stored params
   *
   * @return {FullPageLocalStorage}
   */
  getSignupParams (): FullPageLocalStorage {
    const params = this.localStorage.getItem(LS_FULL_EVENT_DATA_KEY);
    return params ? JSON.parse(params) : {};
  }

  /**
   * Makes attempt to Sign Up in an event if it is crowded
   */
  processSignup (): void {
    if (!this.canSignup()) {
      throw Error('There are no required params. Sign up for the event not possible.');
    }

    const { data, redirectUrl } = this.getSignupParams();

    if (redirectUrl) {
      this.window.location.href = redirectUrl;
      return;
    }

    if (!_.isNil(data)) {
      void this.http.post(`/api/v3/event-session/signup`, data).pipe( //NOSONAR
        pluck('result'),
        tap(({ redirectURL }) => {
          this.window.location.href = redirectURL;
        }),
      ).toPromise();
    }
  }
}
