import { Injectable } from '@angular/core';
import * as SharedInterfaces from '@shared/interfaces';
import { EventEntryType } from '@shared/enums';
import * as SharedEnums from '@shared/enums';

@Injectable()
export class EventHelperService {
  /**
   * Returns `true` if event is in RSVP state.
   *
   * @param  {V1Event} event
   * @return {boolean}
   */
  isRSVP (
    event: SharedInterfaces.V1Event,
  ): boolean {
    if (_.isNil(event) === true) {
      return false;
    }

    return !(this.isOpen(event) || event.end_time < new Date());
  }

  /**
   * Returns `true` if event is open.
   *
   * @param  {V1Event} event
   * @return {boolean}
   */
  isOpen (
    event: SharedInterfaces.V1Event,
  ): boolean {
    if (_.isNil(event) === true) {
      return false;
    }

    if (this.isEventWatercooler(event) === true) {
      return true;
    }

    if (!_.isNil(event.is_open)) {
      return event.is_open;
    }

    const currentTime = new Date();
    const startTime = new Date(event.start_time);
    startTime.setHours(startTime.getHours() - 1);

    return currentTime >= startTime && currentTime < event.end_time;
  }

  /**
   * Returns `true` if event is ended.
   *
   * @param  {V1Event} event
   * @return {boolean}
   */
  isOver (
    event: SharedInterfaces.V1Event,
  ): boolean {
    if (_.isNil(event) === true) {
      return false;
    }

    return !this.isOpen(event) && event.end_time < new Date() && this.isEventWatercooler(event) === false;
  }

  /**
   * Checks that obtained object is V1EventSeries
   *
   * @param {V1Event | V1EventSeries} event
   * @return {boolean}
   */
  isEventSeries (
    event: SharedInterfaces.V1Event | SharedInterfaces.V1EventSeries,
  ): event is SharedInterfaces.V1EventSeries {
    return 'events' in event;
  }

  /**
   * Returns `true` if event is paid.
   *
   * @param  {V1Event | V1EventSeries} event
   * @return {boolean}
   */
  isPaid (
    event: SharedInterfaces.V1Event | SharedInterfaces.V1EventSeries,
  ): boolean {
    if (_.isNil(event) === true) {
      return false;
    }

    if (this.isEventSeries(event)) {
      return event.entry_type_id === EventEntryType.SeriesPass;
    }

    return event.price > 0
      || event.entry_type_id === EventEntryType.PayBefore
      || event.entry_type_id === EventEntryType.PayAfter;
  }

  /**
   * Returns `true` if event from series events.
   *
   * @param  {V1Event} event
   * @return {boolean}
   */
  isSeries (
    event: SharedInterfaces.V1Event,
  ): boolean {
    if (_.isNil(event) === true) {
      return false;
    }

    return !!event.event_series_id;
  }

  /**
   * Returns `true` if Series Pass event.
   *
   * @param  {V1Event} event
   * @return {boolean}
   */
  isSeriesPass (
    event: SharedInterfaces.V1Event,
  ): boolean {
    if (_.isNil(event) === true) {
      return false;
    }

    return event.entry_type_id === EventEntryType.SeriesPass && this.isSeries(event);
  }

  /**
   * Returns `true` if event's type is Watercooler.
   *
   * @param  {V1Event} event
   * @return {boolean}
   */
  isEventWatercooler (
    event: SharedInterfaces.V1Event,
  ): boolean {
    if (_.isNil(event) === true) {
      return false;
    }

    return event.eventType === SharedEnums.EventTypeWithinSeries.Watercooler;
  }
}
