export enum EventState {
  Hidden = 0, // Deleted
  Visible = 1, // Active
}

export enum LMSEventType {
  Class = 'CLASS',
  OfficeHours = 'OFFICE_HOURS',
  DiscussionSection = 'DISCUSSION_SECTION',
  Custom = 'CUSTOM',
}

export enum EventType {
  None = 1,
  GuestList = 2,
  PromoCode = 3,
  Facebook = 4,
  Linkedin = 5,
  SamlSso = 6,
}

export enum UserRole {
  Regular = 5,
  Admin = 8,
}

export enum EventStatus {
  Over = 'over',
  Open = 'open',
  RSVP = 'rsvp',
}

export enum EventEntryType {
  Free = 1,
  PayBefore = 2,
  PayAfter = 3,
  SeriesPass = 4,
}

export enum SignUpResponseType {
  JoinEvent = 0,
  Payment = 1,
}

export enum RSVPResponseType {
  RSVPCreated = 'RSVPCreated',
  PaymentRedirect = 'PaymentRedirect',
}

export enum SeriesRepeatType {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  EveyMondayWednesdayFriday = 'MWF',
  EveryTuesdayThursday = 'TT',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Custom = 'CUSTOM',
}

export enum EventTypeWithinSeries {
  Default = 0,
  Lobby = 1,
  Watercooler = 2,
}

export enum AppType {
  Shindig = 'shindig',
  Watercoolr = 'watercoolr',
}
