import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { SvgIconRegistry } from '@core/services/svg-icon-registry.service';
import { IconNameSubset, SvgIcon } from '@assets/svg';

@Component({
  selector: 'sd-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SvgIconComponent {
  private svgIcon: SVGElement;

  @Input()
  set name (iconName: IconNameSubset<SvgIcon[]>) {
    if (_.isNil(this.svgIcon) === false) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    this.svgIcon = this.svgIconRegistry.getIconElement(iconName);
    this.element.nativeElement.appendChild(this.svgIcon);
  }

  constructor (
    private element: ElementRef,
    private svgIconRegistry: SvgIconRegistry,
  ) { }
}
