import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class CustomDialogService {
  dialogRef: MatDialogRef<any>;

  constructor (
    private dialog: MatDialog,
  ) { }

  async open<D = any, R = any, C = any> (
    component: ComponentType<C>,
    options: D | MatDialogConfig<D> = {},
  ): Promise<R> {
    const { data, ...params } = (options as MatDialogConfig);
    const config = _.isNil(data) === false
      ? { data, ...params } : { data: params };

    this.dialogRef = this.dialog.open(component, config);
    return this.dialogRef.afterClosed().toPromise();
  }
}
