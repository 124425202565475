import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter, Input,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  selector: '[sdFormControlError], [sdFormControlErrorChanged]',
})
export class FormControlErrorDirective implements AfterContentInit, OnDestroy {
  private changes: MutationObserver;

  @Input('sdFormControlSelector')
  public selector?: string;

  @Output('sdFormControlErrorChanged')
  public onErrorStateChanged = new EventEmitter<boolean>();

  constructor (
    private elementRef: ElementRef,
  ) { }

  ngAfterContentInit (): void {
    const element = _.isNil(this.selector)
      ? this.elementRef.nativeElement
      : this.elementRef.nativeElement.querySelector(this.selector);

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if (mutation.attributeName === 'class') {
          const isInvalid = element.classList.contains('ng-invalid') as boolean;
          const isTouched = element.classList.contains('ng-touched') as boolean;
          const isDirty = element.classList.contains('ng-dirty') as boolean;

          const isError = isInvalid && (isDirty || isTouched);
          this.onErrorStateChanged.emit(isError);
        }
      });
    });

    this.changes.observe(element, {
      attributes: true,
    });
  }

  ngOnDestroy (): void {
    this.changes.disconnect();
  }
}
