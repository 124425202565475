import { Injectable } from '@angular/core';
import * as KrixRS from '@krix/resource-store';

import * as Enums from '@shared/enums';
import * as Interfaces from '@shared/interfaces';

@Injectable()
export class LegacyEventResourceStore extends KrixRS.ResourceStore<Interfaces.LegacyEvent> {
  public name = 'legacyEvent';

  public schema: KrixRS.Interfaces.Schema<Interfaces.LegacyEvent> = {
    id: KrixRS.Enums.SchemaType.Number,
    title: KrixRS.Enums.SchemaType.String,
    subheading: KrixRS.Enums.SchemaType.String,
    description: KrixRS.Enums.SchemaType.String,
    logo: KrixRS.Enums.SchemaType.String,
    banner_image: KrixRS.Enums.SchemaType.String,
    created_by_id: KrixRS.Enums.SchemaType.Number,
    create_time: KrixRS.Enums.SchemaType.Date,
    update_time: KrixRS.Enums.SchemaType.Date,
    service_email: KrixRS.Enums.SchemaType.String,
    entry_type_id: KrixRS.Enums.SchemaType.Number,
    price: KrixRS.Enums.SchemaType.Number,
    currency: KrixRS.Enums.SchemaType.String,
    start_time: KrixRS.Enums.SchemaType.Date,
    end_time: KrixRS.Enums.SchemaType.Date,
    state: KrixRS.Enums.SchemaType.Number,
    event_type_id: KrixRS.Enums.SchemaType.Number,
    show_social_login: KrixRS.Enums.SchemaType.Boolean,
    simultaneous_user_limit: KrixRS.Enums.SchemaType.Number,
    slug: KrixRS.Enums.SchemaType.String,
    rsvp_url: KrixRS.Enums.SchemaType.String,
    hashtag: KrixRS.Enums.SchemaType.String,
    recurring: KrixRS.Enums.SchemaType.Number,
    send_reminder_minutes_prior: KrixRS.Enums.SchemaType.Number,
    reminders_sent: KrixRS.Enums.SchemaType.Number,
    reminder_sender_name: KrixRS.Enums.SchemaType.String,
    reminder_email_template_id: KrixRS.Enums.SchemaType.Number,
    confirmation_email_template_id: KrixRS.Enums.SchemaType.Number,
    show_on_website: KrixRS.Enums.SchemaType.Boolean,
    series_event_number: KrixRS.Enums.SchemaType.Number,
    event_series_id: KrixRS.Enums.SchemaType.Number,
    queue_user_threshold: KrixRS.Enums.SchemaType.Number,
    queue_delay: KrixRS.Enums.SchemaType.Number,
    demo: KrixRS.Enums.SchemaType.Boolean,
    organization: KrixRS.Enums.SchemaType.String,
    course_name: KrixRS.Enums.SchemaType.String,
    lms_type_id: KrixRS.Enums.SchemaType.Number,
    time_zone: KrixRS.Enums.SchemaType.String,
    country: KrixRS.Enums.SchemaType.String,
    background: KrixRS.Enums.SchemaType.String,
    agent_id: KrixRS.Enums.SchemaType.String,
    show_welcome_slide: KrixRS.Enums.SchemaType.Boolean,
    welcome_slide_activated: KrixRS.Enums.SchemaType.Boolean,
    promo_code: KrixRS.Enums.SchemaType.String,
    tcp_udp: KrixRS.Enums.SchemaType.String,
    enable_client_logging: KrixRS.Enums.SchemaType.Number,
    log_debug: KrixRS.Enums.SchemaType.Number,
    log_info: KrixRS.Enums.SchemaType.Number,
    log_warn: KrixRS.Enums.SchemaType.Number,
    log_error: KrixRS.Enums.SchemaType.Number,
    show_on_mobile: KrixRS.Enums.SchemaType.Boolean,
    max_users_per_room: KrixRS.Enums.SchemaType.Number,
    send_reminders_to: KrixRS.Enums.SchemaType.String,
    organization_id: KrixRS.Enums.SchemaType.Number,
    lms_context_id: KrixRS.Enums.SchemaType.String,
    lms_event_type: KrixRS.Enums.SchemaType.String,
    lms_id: KrixRS.Enums.SchemaType.String,
    send_additional_reminder_minutes_prior: KrixRS.Enums.SchemaType.Number,
    additional_reminder_text: KrixRS.Enums.SchemaType.String,
    room_chats_show_threshold: KrixRS.Enums.SchemaType.Number,
    room_chats_are_enabled: KrixRS.Enums.SchemaType.Boolean,
    room_chats_show_participants_threshold: KrixRS.Enums.SchemaType.Number,
    pods_amount: KrixRS.Enums.SchemaType.Number,
    show_series_pod: KrixRS.Enums.SchemaType.Boolean,
    privacy_status: KrixRS.Enums.SchemaType.Boolean,
    eventType: KrixRS.Enums.SchemaType.Number,
    introVideoIsShown: KrixRS.Enums.SchemaType.Boolean,
    entryFields: KrixRS.Enums.SchemaType.Object,
    consentStatement: KrixRS.Enums.SchemaType.Object,

    isInWatercoolerMode: {
      type: KrixRS.Enums.SchemaType.Computed,
      compute: (event) => {
        return event.eventType === Enums.EventTypeWithinSeries.Watercooler;
      },
    },

    logoWithDefault: {
      type: KrixRS.Enums.SchemaType.Computed,
      compute: (event) => {
        return this.getImageWithDefault(
          event, event.logo, `https://cdn.shindig.com/static/images/watercoolr-default-logo.svg`,
        );
      },
    },

    bannerWithDefault: {
      type: KrixRS.Enums.SchemaType.Computed,
      compute: (event) => {
        return this.getImageWithDefault(
          event, event.banner_image, `https://cdn.shindig.com/static/images/watercoolr-default-banner.svg`,
        );
      },
    },
  };

  /**
   * Returns the image URL. If event is in watercooler mode and image isn't defined, method'll return
   * default image.
   *
   * @param  {Interfaces.LegacyEvent} event
   * @param  {string} image
   * @param  {string} defaultImage
   * @return {string}
   */
  getImageWithDefault (
    event: Interfaces.LegacyEvent,
    image: string,
    defaultImage: string,
  ): string {
    if (event.isInWatercoolerMode === false) {
      return image;
    }

    return image === 'default' ? defaultImage : image;
  }
}
