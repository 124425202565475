import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmComponent } from '../components';
import { ConfirmDialogData } from '../interfaces';

@Injectable()
export class ConfirmDialogService {
  dialogRef: MatDialogRef<ConfirmComponent>;

  constructor (
    private dialog: MatDialog,
  ) { }

  async open (options: ConfirmDialogData | MatDialogConfig<ConfirmDialogData>): Promise<boolean> {
    const { data, ...params } = (options as MatDialogConfig);
    const config = _.isNil(data) === false
      ? { data, disableClose: true, ...params }
      : { data: params, disableClose: true };

    this.dialogRef = this.dialog.open(ConfirmComponent, config);
    return this.dialogRef.afterClosed().toPromise();
  }
}
