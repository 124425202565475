import { AbstractControl } from '@angular/forms';

/**
 * Validates that a value is not a blank string after spaces trimming.
 * e.g.
 * ```
 *   ''        - invalid
 *   ' '       - invalid
 *   'lorem'   - valid
 *   ' ipsum ' - valid
 * ```
 *
 * @export
 * @param {AbstractControl} control
 */
export const noWhitespaceValidator = (
  control: AbstractControl,
): { whitespace: boolean } | null => {
  const isWhitespace = (control.value ?? '').trim().length === 0;
  return isWhitespace ? { whitespace: true } : null;
};
