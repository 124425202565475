import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SvgIconRegistry } from '@core/services/svg-icon-registry.service';
import { iconFacebookCircle, iconTwitterCircle, iconEmailCircle } from '@assets/svg';

@Component({
  selector: 'sd-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaComponent {
  urlFacebook = 'https://www.facebook.com/OfficialShindig';
  urlTwitter = 'https://www.twitter.com/shindigevents';
  email = 'info@shindigevents.com';

  constructor (
    private svgIconRegistry: SvgIconRegistry,
  ) {
    this.svgIconRegistry.registerIcons([
      iconFacebookCircle,
      iconTwitterCircle,
      iconEmailCircle,
    ]);
  }
}
