<ng-container *ngIf="!isHidden">
  <sd-form-field
    *ngFor="let formGroup of form | mapFn : getEntryFields; let i = index"
    [formGroup]="formGroup"
    class="field"
  >
    <input
      type="text"
      [placeholder]="entryFieldsMap[formGroup.get('id')?.value]"
      [title]="entryFieldsMap[formGroup.get('id')?.value]"
      [name]="[entryFieldsMap[formGroup.get('id')?.value], i] | mapFn: toInputName"
      formControlName="value"
    />
  </sd-form-field>
</ng-container>
