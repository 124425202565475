import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface PaymentDialogData {
  redirectURL: string;
  eventSeriesSlug: string;
}

export interface PaymentDialogResponse {
  redirectURL: string;
}

@Component({
  selector: 'sd-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDialogComponent {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: PaymentDialogData,
    private mdDialogRef: MatDialogRef<PaymentDialogComponent, PaymentDialogResponse>,
  ) { }

  confirm (): void {
    this.mdDialogRef.close({
      redirectURL: this.data.redirectURL,
    });
  }
}
