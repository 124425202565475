<a
  class="social-icon social-facebook"
  [href]="urlFacebook"
  target="_blank"
  rel="noopener noreferrer"
  aria-label="Facebook"
>
  <sd-svg-icon name="facebook-circle"></sd-svg-icon>
</a>

<a
  class="social-icon social-twitter"
  [href]="urlTwitter"
  target="_blank"
  rel="noopener noreferrer"
  aria-label="Twitter"
>
  <sd-svg-icon name="twitter-circle"></sd-svg-icon>
</a>

<a
  class="social-icon social-email"
  href="mailto:{{ email }}"
  target="_blank"
  rel="noopener noreferrer"
  aria-label="Send email"
>
  <sd-svg-icon name="email-circle"></sd-svg-icon>
</a>
