import { Injectable } from '@angular/core';

@Injectable()
export class SharedHelperService {
  /**
   * Renames in given object keys by keysMap
   *
   * ```ts
   * const initObject = {
   *   someOldKey: 'value',
   *   normalKey: 'other value',
   * };
   *
   * const keyMap = {
   *   someOldKey: 'newKey',
   * };
   * const result = renameKeys(initObject, keyMap);
   * // result is {
   * //   newKey: 'value',
   * //   normalKey: 'other value',
   * // }
   * ```
   *
   * @param obj
   * @param keysMap
   */
  renameKeys (
    obj: { [key: string]: any },
    keysMap: { [key: string]: string },
  ): { [key: string]: any } {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = keysMap[key] || key;
      return { [newKey]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
  }

  /**
   * Changes all values in given object to Date by list of keys
   *
   * ```ts
   * const event = {
   *   id: 1,
   *   title: 'Super Event',
   *   start_time: '2021-03-18T19:20:00.000Z',
   *   end_time: '2021-03-18T20:00:00.000Z',
   * };
   * const result = transformToDate(['start_time', 'end_time'])(event);
   * ```
   * result will contain object with Dates in 'start_time' and 'end_time' keys
   *
   * @param keys
   */
  transformToDate<T> (keys: (keyof T)[]) {
    return (item: T): T => {
      if (_.isNil(item)) {
        return {} as T;
      }
      const datesItem: { [key: string]: Date } = {};
      const dateKeys = [ ...keys, 'createdAt', 'updatedAt' ] as (keyof T)[];

      dateKeys.forEach((key: keyof T) => {
        if (typeof item[key] === 'string') {
          datesItem[key as string] = new Date(item[key] as any);
        }
      });

      return { ...item, ...datesItem } as T;
    };
  }
}
