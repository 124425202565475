import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'event',
    loadChildren: async () => {
      const moduleFile = await import('./modules/event-entry/event-entry.module');
      return moduleFile.EventEntryModule;
    },
  },
  {
    path: 'admin/event',
    loadChildren: async () => {
      const moduleFile = await import('./modules/event-entry/event-entry.module');
      return moduleFile.EventEntryModule;
    },
    data: { isAdmin: true },
  },
  {
    path: 'client-demo',
    loadChildren: async () => {
      const moduleFile = await import('./modules/event-entry/event-entry.module');
      return moduleFile.EventEntryModule.forDemoMode(false);
    },
    data: { slug: 'demo-event' },
  },
  {
    path: 'admin-demo',
    loadChildren: async () => {
      const moduleFile = await import('./modules/event-entry/event-entry.module');
      return moduleFile.EventEntryModule.forDemoMode(true);
    },
    data: {
      isAdmin: true,
      slug: 'demo-event',
    },
  },
  {
    path: 'series',
    loadChildren: async () => {
      const moduleFile = await import('./modules/event-series/event-series.module');
      return moduleFile.EventSeriesModule;
    },
  },
  {
    path: 'full',
    loadChildren: async () => {
      const moduleFile = await import('./modules/event-full/event-full.module');
      return moduleFile.EventFullModule;
    },
  },
  {
    path: 'rsvp-thanks',
    loadChildren: async () => {
      const moduleFile = await import('./modules/thank-you-page/thank-you-page.module');
      return moduleFile.ThankYouPageModule;
    },
  },
  {
    path: 'auth',
    loadChildren: async () => {
      const moduleFile = await import('./modules/oauth2/oauth2.module');
      return moduleFile.OAuth2Module;
    },
  },
  {
    path: 'intro',
    loadChildren: async () => {
      const moduleFile = await import('./modules/event-intro/event-intro.module');
      return moduleFile.EventIntroModule;
    },
  },
  {
    path: 'error',
    loadChildren: async () => {
      const moduleFile = await import('./modules/not-found-page/not-found-page.module');
      return moduleFile.NotFoundPageModule;
    },
  },
  {
    path: '',
    redirectTo: '/error',
    pathMatch: 'full',
  },
  {
    path: '**',
    loadChildren: async () => {
      const moduleFile = await import('./modules/not-found-page/not-found-page.module');
      return moduleFile.NotFoundPageModule;
    },
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule { }
