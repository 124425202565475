import isEmail, { IsEmailOptions } from 'validator/es/lib/isEmail';
import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Validates that a value is email according to the `validator.js` library.
 *
 * @export
 * @param {IsEmailOptions} options
 */
export function emailValidator (options?: IsEmailOptions): ValidatorFn {
  return (control: AbstractControl): { email: boolean } | null => {
    return isEmail(control.value, options) ? null : { email: true };
  };
}
