<ng-container *ngIf="isShowHeader()">
  <div *ngIf="banner" class="banner">
    <img
      #bannerImg
      [src]="banner"
      alt="Event banner"
      role="banner"
      (load)="onLoad(bannerImg, 'banner')"
      [style.--banner-ratio]="bannerProperties?.ratio"
      [style.--banner-width.vw]="bannerProperties?.width"
      [style.--banner-max-width.px]="bannerProperties?.maxWidth"
    >
  </div>
  <div *ngIf="logo" class="logo">
    <img
      #logoImg
      [src]="logo"
      alt="Event Logo"
      (load)="onLoad(logoImg, 'logo')"
      [style.--logo-ratio]="logoProperties?.ratio"
      [style.--logo-width.vw]="logoProperties?.width"
      [style.--logo-max-width.px]="logoProperties?.maxWidth"
    >
  </div>
</ng-container>
