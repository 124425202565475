import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { APIResult, EventEntryField, LoginData, UserData, V1Event, V1EventSeries } from '@shared/interfaces';
import { LOCAL_STORAGE, WINDOW } from '../tokens';
import { RSVPResponseType, SignUpResponseType, UserRole } from '@shared/enums';
import { ErrorCode } from '../../modules/event-entry/shared/enums';
import { AlertDialogService } from '../modules/dialogs/services/alert-dialog.service';
import { CustomDialogService } from '../modules/dialogs/services/custom-dialog.service';
import { SpeedtestService } from './speedtest.service';
import { FullEventService } from './full-event.service';
import { MobileService } from './mobile.service';
import { SharedHelperService } from './shared-helper.service';
import { EventHelperService } from '@core/services/event-helper.service';
import {
  PaymentDialogComponent,
  PaymentDialogData,
  OpenMobileAppDialogComponent,
} from '@shared/components';
import { DateTime } from 'luxon';
import { SeriesPassJoinStatus } from '@shared/enums/payment.enums';
import { ActivatedRoute, Router } from '@angular/router';

import { LegacyEventResourceStore } from '@resources';

import * as EventEnums from '@shared/enums/event.enum';

interface SignupResponse {
  token: string;
  redirectURL: string;
  responseType: SignUpResponseType;
}

interface RsvpResponse {
  responseType: RSVPResponseType;
  redirectURL?: string;
}

const eventDateFields: (keyof V1Event)[] = ['create_time', 'update_time', 'start_time', 'end_time'];
const eventSeriesDateFields: (keyof V1EventSeries)[] = ['create_time', 'update_time', 'start_time', 'end_time'];

@Injectable()
export class EventService {
  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected http: HttpClient,
    @Inject(WINDOW) protected window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private meta: Meta,
    private title: Title,
    private alertDialog: AlertDialogService,
    private customDialog: CustomDialogService,
    private sharedHelper: SharedHelperService,
    private eventHelper: EventHelperService,
    private speedtest: SpeedtestService,
    private fullEventService: FullEventService,
    private mobileService: MobileService,
    private legacyEventResourceStore: LegacyEventResourceStore,
  ) { }

  /**
   * Returns an event instance by event slug.
   *
   * @param  {string} slug
   * @return {Promise<V1Event>}
   */
  async getEvent (slug: string): Promise<V1Event> {
    const event = await this.http.get<V1Event>(`/api/events/slug/${slug}`).toPromise();
    return this.sharedHelper.transformToDate<V1Event>(eventDateFields)(event);
  }

  /**
   * Returns an event series instance by event slug with relations (events).
   *
   * @param  {string} slug
   * @return {Promise<V1EventSeries>}
   */
  async getEventSeriesBySlug (slug: string): Promise<V1EventSeries> {
    const response = await this.http.get<APIResult<V1EventSeries>>(`/api/v3/event-series/slug/${slug}`)
      .toPromise();
    const eventSeries = this.sharedHelper.transformToDate<V1EventSeries>(eventSeriesDateFields)(response.result);
    const events = eventSeries.events
      .map(this.sharedHelper.transformToDate<V1Event>(eventDateFields))
      .sort((a, b) => {
        return a.start_time.getTime() - b.start_time.getTime();
      });

    return { ...eventSeries, events };
  }

  /**
   * Starts testing of network speed
   * If force === false, returns previous speedtest data, if it's available
   */
  startSpeedTest (
    force: boolean = false,
  ): void {
    const speedtest = this.activatedRoute.snapshot.queryParamMap.get('speedtest');
    if (['false', 'off', '0'].includes(speedtest)) {
      void this.speedtest.startMockTest(); //NOSONAR
      return;
    }

    if (force !== true) {
      void this.speedtest.getData();
      return;
    }

    void this.speedtest.startTest(); //NOSONAR
  }

  /**
   * Enters into the event
   *
   * @param {Partial<LoginData>} data
   * @param {V1Event} event
   * @return {Promise<void>}
   */
  async processSignup (data: Partial<LoginData>, event: V1Event): Promise<void> {
    const speedTest = await this.speedtest.getData();

    const body = this.sharedHelper.renameKeys({ ...data, speedTest }, {
      name: 'username',
      email: 'password',
      entryFields: 'entryData',
      speedTest: 'bandwidthResults',
    });

    const isPaidEvent = ({ responseType }: SignupResponse): boolean => {
      return responseType === SignUpResponseType.Payment;
    };
    const redirectToUrl = ({ redirectURL }: Pick<SignupResponse, 'redirectURL'>): void => {
      this.window.location.href = redirectURL;
    };

    this.storeLoginDataForSeries(event, data);
    this.fullEventService.setSignupData(body);

    const response = await this.http.post<APIResult<SignupResponse>>(`/api/v3/event-session/signup`, body).toPromise();
    const signupResponse = response.result;

    if (isPaidEvent(signupResponse) && this.eventHelper.isSeriesPass(event)) {
      const dialogResponse = await this.customDialog.open<PaymentDialogData>(PaymentDialogComponent, {
        redirectURL: signupResponse.redirectURL,
        eventSeriesSlug: event.event_series_slug,
      });
      if (_.has(dialogResponse, 'redirectURL')) {
        return redirectToUrl(dialogResponse);
      }
      return;
    }

    if (isPaidEvent(signupResponse)) {
      return redirectToUrl(signupResponse);
    }

    if (data.role === UserRole.Admin) {
      this.localStorage.setItem(`eventAdminUserToken`, signupResponse.token);
      await this.goToEvent(event.slug, signupResponse.redirectURL, UserRole.Admin);
    } else {
      this.localStorage.setItem(`eventRegularUserToken`, signupResponse.token);
      await this.goToEvent(event.slug, signupResponse.redirectURL, UserRole.Regular);
    }
  }

  /**
   * Sends RSVP for the event to user
   *
   * @param {Partial<LoginData>} data
   * @param {V1Event} event
   * @return {Promise<void>}
   */
  async processRSVP (data: Partial<LoginData>, event: V1Event): Promise<any> {
    const timezone = DateTime.local().toFormat('z'); // 'America/New_York'
    const { eventId, seriesId, ...user } = data;
    const body = {
      eventId,
      seriesId,
      user: {
        ...user,
        timezone,
      },
    };

    this.storeLoginDataForSeries(event, data);

    let rsvpResponse: RsvpResponse;
    try {
      const response = await this.http.post<APIResult<RsvpResponse>>(`/api/v3/rsvp/event-with-payment`, body)
        .toPromise();
      rsvpResponse = response.result;
    } catch (e) {
      let message = 'Please make sure all your details are filled.';
      if (e.error.code === ErrorCode.GuestList) {
        message = `Your email is not on the guest list for the event.`;
      }
      if (e.error.code === ErrorCode.PromoCode) {
        message = `The Password is incorrect.`;
      }
      return this.alertDialog.open({
        title: 'Error submitting your RSVP',
        message,
      });
    }

    const isNeedToPay = this.eventHelper.isPaid(event)
      && (rsvpResponse.responseType === RSVPResponseType.PaymentRedirect);
    const isShowModal = this.eventHelper.isSeriesPass(event) && isNeedToPay;

    if (isShowModal) {
      const dialogResponse = await this.customDialog.open<PaymentDialogData>(PaymentDialogComponent, {
        redirectURL: rsvpResponse.redirectURL,
        eventSeriesSlug: event.event_series_slug,
      });
      if (_.has(dialogResponse, 'redirectURL')) {
        this.window.location.href = dialogResponse.redirectURL;
        return;
      }
      return;
    }

    if (isNeedToPay) {
      this.window.location.href = rsvpResponse.redirectURL;
      return;
    }

    const thanksUrl = `${this.window.location.origin}/login/rsvp-thanks/${event.slug}`;
    const promoUrl = `https://shindig.com/rsvp-thanks/?url=${btoa(thanksUrl)}`;
    this.window.location.href = promoUrl;
  }

  /**
   * Create RSVP subscriptions for specific events and user's name and email.
   *
   * @param  {number[]} eventIds
   * @param  {UserData} user
   * @return {Promise<void>}
   */
  async createRSVPSubscriptionsForEvents (eventIds: number[], user: UserData): Promise<boolean> {
    try {
      await this.http.post<void>(`/api/v3/rsvp`, { eventIds, user }).toPromise();
      await this.alertDialog.open({
        title: 'RSVP Submitted',
        message: 'Thank You!',
      });
      return true;
    } catch (e) {
      await this.alertDialog.open({
        title: 'Error submitting your RSVP',
        message: 'Something went wrong.',
      });
      return false;
    }
  }

  /**
   * Enters into the event through Facebook login
   *
   * @param {Partial<LoginData>} data
   * @param {V1Event} event
   * @return {Promise<void>}
   */
  async processFacebookSignup (data: Partial<LoginData>, event: V1Event): Promise<void> {
    const speedTest = await this.speedtest.getData();

    const dataParam = this.sharedHelper.renameKeys({ ...data, speedTest }, {
      entryFields: 'entryData',
      speedTest: 'bandwidthResults',
    });

    const params = new HttpParams().set('state', JSON.stringify(dataParam));
    const url = `${environment.apiUrl}/api/v3/auth/login/facebook?${params.toString()}`;

    this.fullEventService.setSignupUrl(url);

    this.window.location.href = url;
  }

  /**
   * Enters into the event through LinkedIn login
   *
   * @param {Partial<LoginData>} data
   * @param {V1Event} event
   * @return {Promise<void>}
   */
  async processLinkedInSignup (data: Partial<LoginData>, event: V1Event): Promise<void> {
    const speedTest = await this.speedtest.getData();

    const dataParam = this.sharedHelper.renameKeys({ ...data, speedTest }, {
      entryFields: 'entryData',
      speedTest: 'bandwidthResults',
    });

    const params = new HttpParams().set('state', JSON.stringify(dataParam));
    const url = `${environment.apiUrl}/api/v3/auth/login/linkedin?${params.toString()}`;

    this.fullEventService.setSignupUrl(url);

    this.window.location.href = url;
  }

  /**
   * Enters into the event through external service with SAML SSO login
   *
   * @param {Partial<LoginData>} data
   * @param {V1Event} event
   * @return {void}
   */
  processSamlSsoSignup (data: Partial<LoginData>, event: V1Event): void {
    const dataParam = this.sharedHelper.renameKeys(data, {
      name: 'username',
    });

    let params = new HttpParams()
      .set('event', event.slug)
      .set('eventId', `${event.id}`);

    Object.keys(dataParam).forEach((param) => {
      params = params.set(param, JSON.stringify(dataParam[param]));
    });

    const url = `${environment.apiUrl}/api/v3/auth/login/saml?${params.toString()}`;

    this.fullEventService.setSignupUrl(url);

    this.window.location.href = url;
  }

  /**
   * Creates Series Pass payment. Returns Payment service URL.
   *
   * @param  {number} seriesId
   * @param  {UserData} user
   * @return {Promise<void>}
   */
  async createSeriesPassPayment (seriesId: number, user: UserData): Promise<any> {
    let data;
    try {
      const response = await this.http.post<APIResult<{ redirectUrl: string, joinStatus: SeriesPassJoinStatus }>>(
        `/api/v3/payments/purchase-pass`,
        { seriesId, user },
      ).toPromise();
      data = response.result;
    } catch (e) {
      return this.alertDialog.open({
        title: 'Something went wrong',
      });
    }

    const isAllowedToJoin = ({ joinStatus }): boolean => {
      return joinStatus === SeriesPassJoinStatus.AllowedToJoin;
    };

    if (!isAllowedToJoin(data)) {
      this.window.location.href = data.redirectUrl;
      return;
    }

    return this.alertDialog.open({
      title: 'You have already paid for the series pass',
    });
  }

  /**
   * Check Series Pass payment status.
   *
   * @param  {number} seriesId
   * @param  {UserData} user
   * @return {Promise<SeriesPassJoinStatus>}
   */
  async checkSeriesPassStatus (seriesId: number, user: UserData): Promise<SeriesPassJoinStatus> {
    const response = await this.http.post<APIResult<{ joinStatus: SeriesPassJoinStatus }>>(
      `/api/v3/payments/join-status`,
      { seriesId, user },
    ).toPromise();
    return response?.result?.joinStatus ?? SeriesPassJoinStatus.PaymentNeeded;
  }

  /**
   * Redirects into the event
   *
   * @param {string} eventSlug
   * @param {string} eventToken
   * @return Promise<void>
   */
  async userSignIn (eventSlug: string, eventToken: string): Promise<void> {
    if (this.mobileService.isMobile()) {
      // To transfer users to the mobile app we need a user gesture inside our site page.
      await this.customDialog.open(OpenMobileAppDialogComponent, {
        eventSlug,
        eventToken,
      });
      return;
    }

    this.localStorage.setItem(`eventRegularUserToken`, eventToken);
    const loginURL = `${environment.clientUrl}/?event=${eventSlug}&token=${eventToken}`;
    await this.goToEvent(eventSlug, loginURL, UserRole.Regular);
  }

  /**
   * Moves the user to Event page (or Intro page).
   *
   * @param  {string} eventSlug
   * @param  {string} loginURL
   * @param  {UserRole} userRole
   * @return {Promise<void>}
   */
  async goToEvent (
    eventSlug: string,
    loginURL: string,
    userRole: UserRole,
  ): Promise<void> {
    // add all exist query params to the loginURL
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const clientLoginURL = new URL(loginURL);
    Object.keys(queryParams).forEach((key) => {
      clientLoginURL.searchParams.append(key, queryParams[key]);
    });

    this.localStorage.setItem(`lastLoginURL`, clientLoginURL.toString());

    const legacyEvent = this.legacyEventResourceStore.findOne({
      slug: eventSlug,
    });

    const introShownCount = +(localStorage.getItem('introShownCount') ?? 0);
    if (
      introShownCount < 3
      && userRole === UserRole.Regular
      && legacyEvent?.introVideoIsShown === true
      && _.isNil(_.get(queryParams, 'sailthrough')) === true
    ) {
      this.localStorage.setItem(`introShownCount`, `${introShownCount + 1}`);
      await this.router.navigate([ '/intro/client' ], {
        queryParams: {
          mode: legacyEvent.eventType === EventEnums.EventTypeWithinSeries.Watercooler
            ? EventEnums.AppType.Watercoolr : EventEnums.AppType.Shindig,
        },
      });
      return;
    }

    this.window.location.href = clientLoginURL.toString();
  }

  /**
   * Set different meta information to html page
   *
   * @param {V1Event | V1EventSeries} event
   * @return {void}
   */
  setPageMeta (event: V1Event | V1EventSeries): void {
    this.title.setTitle(`${event.title} - Shindig.com`);
    this.meta.updateTag({ name: 'title', content: event.title });
    this.meta.updateTag({ property: 'og:title', content: event.title });

    if (event.subheading) {
      this.meta.updateTag({ name: 'description', content: event.subheading });
      this.meta.updateTag({ property: 'og:description', content: event.subheading });
    }

    if (event.logo) {
      this.meta.updateTag({ property: 'og:image', content: event.logo });
      this.meta.updateTag({ property: 'og:image:secure_url', content: event.logo });
      this.meta.updateTag({ property: 'og:image:type', content: 'image/png' });
    }
  }

  private storeLoginDataForSeries (event: V1Event, data: Partial<LoginData>): void {
    if (_.isNil(event.event_series_id)) {
      return;
    }

    const values = _.keyBy(data.entryFields, 'id');
    const entryFields = event.entryFields.map((entryField) => {
      return {
        id: entryField.id,
        name: entryField.name,
        value: values[entryField.id]?.value ?? '',
      };
    });

    const userRole = data.role === UserRole.Admin ? 'AdminUser' : 'RegularUser';
    const storageKey = `eventSeries${userRole}${event.event_series_id}`;

    const expirationDate = DateTime.local().plus({ months: 3 }).toISO();

    this.localStorage.setItem(storageKey, JSON.stringify({
      expirationDate,
      data: {
        name: data.name,
        email: data.email,
        entryFields,
      },
    }));
  }

  /**
   * Gets previously saved data from LocalStorage
   * to use it for fill login form in the event from events series
   *
   * @param {number} eventSeriesId
   * @param {UserRole} userRole
   */
  getLoginDataForSeries (eventSeriesId: number, userRole: UserRole): Partial<LoginData> | null {
    if (_.isNil(eventSeriesId)) {
      return null;
    }

    const role = userRole === UserRole.Admin ? 'AdminUser' : 'RegularUser';
    const storageKey = `eventSeries${role}${eventSeriesId}`;

    try {
      const { data, expirationDate } = JSON.parse(this.localStorage.getItem(storageKey));
      const isExpire = DateTime.local() > DateTime.fromISO(expirationDate);
      if (isExpire) {
        this.localStorage.removeItem(storageKey);
        return null;
      }
      return data;
    } catch (e) {
      return null;
    }
  }

  /**
   * Changes the id's in the data.entryFields
   * on the new id's values from entryFields
   * In the events series each new event has it own EntryFields.
   * These EntryFields have different id's from the previously saved login data.
   *
   * @param {Partial<LoginData>} data
   * @param {EventEntryField[]} entryFields
   */
  matchLoginDataForEntryFields (
    data: Partial<LoginData>,
    entryFields: EventEntryField[],
  ): Partial<LoginData> | null {
    if (_.isNil(data) || _.isNil(entryFields)) {
      return null;
    }

    const values = _.keyBy(data.entryFields, 'name');

    const matchedDataEntryFields = entryFields
      .map(({ id, name }) => {
        return {
          id,
          value: values[name]?.value ?? '',
        };
      });

    return {
      ...data,
      entryFields: matchedDataEntryFields,
    };
  }
}
