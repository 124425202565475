import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AlertComponent } from '../components';
import { AlertDialogData } from '../interfaces';

@Injectable()
export class AlertDialogService {
  dialogRef: MatDialogRef<AlertComponent>;

  constructor (
    private dialog: MatDialog,
  ) { }

  async open (options: AlertDialogData | MatDialogConfig<AlertDialogData>): Promise<void> {
    const { data, ...params } = (options as MatDialogConfig);
    const config = _.isNil(data) === false
      ? { data, ...params } : { data: params };
    this.dialogRef = this.dialog.open(AlertComponent, config);
    return this.dialogRef.afterClosed().toPromise();
  }
}
