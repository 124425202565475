import { Component, ChangeDetectionStrategy, Inject, HostBinding } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogData } from '../../interfaces';

@Component({
  selector: 'sd-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @HostBinding('attr.role')
  role = 'alertdialog';

  @HostBinding('attr.aria-labelledby')
  ariaLabelledby='dialog-title';

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
    private mdDialogRef: MatDialogRef<AlertComponent>,
  ) { }

  close (): void {
    this.mdDialogRef.close();
  }
}
