export enum ErrorCode {
  InvalidCredentials = 50001,
  GuestList = 50002,
  PromoCode = 50003,
  SubscriptionExpired = 50005,
}

export const State = {
  PageErrorCode: [ 'eventEntry', 'pageErrorCode' ],
};
