<h1 mat-dialog-title>
  {{ data.title }}
</h1>
<div *ngIf="data.message" mat-dialog-content>
  <p class="dialog-message">
    {{ data.message }}
  </p>
</div>
<div mat-dialog-actions>
  <button class="btn-confirm" (click)="confirm()">
    {{ data.acceptButton || 'OK' }}
  </button>
  <button class="btn-cancel" (click)="cancel()">
    {{ data.cancelButton || 'Cancel' }}
  </button>
</div>
