import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconRegistry } from '@core/services/svg-icon-registry.service';
import { iconPoweredByShindig } from '@assets/svg';

import * as SharedInterfaces from '@shared/interfaces';

@Component({
  selector: 'sd-powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoweredByComponent {
  @Input(`event`)
  public event: SharedInterfaces.LegacyEvent;

  constructor (
    private svgIconRegistry: SvgIconRegistry,
  ) {
    this.svgIconRegistry.registerIcons([iconPoweredByShindig]);
  }
}
