import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PlatformModule } from '@angular/cdk/platform';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
// Pipes
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { MapFnPipe } from './pipes/map-fn.pipe';
import { EventIsOpenPipe } from './pipes/event-is-open.pipe';
import { EventIsRsvpPipe } from './pipes/event-is-rsvp.pipe';
import { EventIsOverPipe } from './pipes/event-is-over.pipe';
import { EventIsSeriesPipe } from './pipes/event-is-series.pipe';
import { EventIsPaidPipe } from './pipes/event-is-paid.pipe';
import { EventIsSeriesPassPipe } from './pipes/event-is-series-pass.pipe';
// Directives
import { AutofocusDirective } from './directives/autofocus.directive';
import { IfMobileDirective } from './directives/if-mobile.directive';
import { IfDesktopDirective } from './directives/if-desktop.directive';
import { FormControlErrorDirective } from './directives/form-control-error.directive';
// Components
import {
  SvgIconComponent,
  SpinnerComponent,
  EntryFieldsComponent,
  ConsentCheckboxComponent,
  FormFieldComponent,
  HeaderComponent,
  PaymentDialogComponent,
  PoweredByComponent,
  SocialMediaComponent,
  OpenMobileAppDialogComponent,
} from './components';


@NgModule({
  declarations: [
    // Pipes
    SafeHtmlPipe,
    SafeUrlPipe,
    MapFnPipe,
    EventIsOpenPipe,
    EventIsRsvpPipe,
    EventIsOverPipe,
    EventIsSeriesPipe,
    EventIsPaidPipe,
    EventIsSeriesPassPipe,
    // Directives
    AutofocusDirective,
    IfMobileDirective,
    IfDesktopDirective,
    FormControlErrorDirective,
    // Components
    SvgIconComponent,
    SpinnerComponent,
    HeaderComponent,
    SocialMediaComponent,
    PoweredByComponent,
    FormFieldComponent,
    EntryFieldsComponent,
    ConsentCheckboxComponent,
    PaymentDialogComponent,
    OpenMobileAppDialogComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    PlatformModule,
    MatDialogModule,
    MatCheckboxModule,
  ],
  exports: [
    // Modules
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    PlatformModule,
    MatDialogModule,
    MatCheckboxModule,
    // Pipes
    SafeHtmlPipe,
    SafeUrlPipe,
    MapFnPipe,
    EventIsOpenPipe,
    EventIsRsvpPipe,
    EventIsOverPipe,
    EventIsSeriesPipe,
    EventIsPaidPipe,
    EventIsSeriesPassPipe,
    // Directives
    AutofocusDirective,
    IfMobileDirective,
    IfDesktopDirective,
    FormControlErrorDirective,
    // Components
    SvgIconComponent,
    SpinnerComponent,
    HeaderComponent,
    SocialMediaComponent,
    PoweredByComponent,
    FormFieldComponent,
    EntryFieldsComponent,
    ConsentCheckboxComponent,
    PaymentDialogComponent,
    OpenMobileAppDialogComponent,
  ],
})
export class SharedModule { }
