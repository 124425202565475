import type { LoDashStatic } from 'lodash';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import has from 'lodash/has';
import map from 'lodash/map';
import capitalize from 'lodash/capitalize';
import keyBy from 'lodash/keyBy';
import omitBy from 'lodash/omitBy';
import isFinite from 'lodash/isFinite';
import trim from 'lodash/trim';
import replace from 'lodash/replace';
import get from 'lodash/get';

interface Environment {
  production: boolean;
  apiUrl: string;
  testSpeedUrl: string;
  clientUrl: string;
  envName: string;
  buildName: string;
}

const lodash = {
  isNil,
  isNaN,
  isEmpty,
  isFunction,
  isObject,
  has,
  map,
  capitalize,
  keyBy,
  omitBy,
  isFinite,
  trim,
  replace,
  get,
} as LoDashStatic;

(window as any)._ = lodash;

import type HlsJS from 'hls.js';

declare global {
  const environment: Environment;
  const _: typeof lodash;
  const Hls: typeof HlsJS;
}
