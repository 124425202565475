import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const urlReq = req.clone({
      url: req.url.startsWith('http') ? req.url : environment.apiUrl + req.url.trim(),
    });

    return next.handle(urlReq);
  }
}
