import {
  Component,
  ChangeDetectionStrategy,
  ContentChild,
  AfterViewInit,
  Input,
  HostBinding,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { FormControlName } from '@angular/forms';
import { Base, CanUnsubscribeClass, mixinUnsubscribe } from '@shared/mixins';

interface ErrorMessagesMap {
  [errorType: string]: string;
}

// Boilerplate for applying mixins to FormFieldComponent.
const MixinBasedClass: CanUnsubscribeClass = mixinUnsubscribe(Base);

@Component({
  selector: 'sd-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent extends MixinBasedClass implements AfterViewInit {
  @ContentChild(FormControlName)
  input: FormControlName;

  @ContentChild(FormControlName, { read: ElementRef })
  inputRef: ElementRef;

  @Input()
  errors: ErrorMessagesMap = {};

  @HostBinding('class.error')
  isError = false;
  errorMessage = '';

  private defaultErrorMessages: ErrorMessagesMap = {
    required: 'This field is required',
    email: 'Please enter a valid email address',
    guestList: 'User must be on Guest List.\nPlease check your email address.',
    whitespace: 'Please enter valid data',
    maxlength: 'The length must not exceed ${requiredLength} characters.\nYou have entered ${actualLength} characters.',
    // ...others default error messages
  };

  constructor (
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterViewInit (): void {
    this.subscription = this.input?.statusChanges?.subscribe(() => {
      const isError = this.input.invalid && (this.input.dirty || this.input.touched);
      this.showErrorMessageWithTimeout(isError);
    });
  }

  private showErrorMessage (isError: boolean): void {
    this.isError = isError;
    this.errorMessage = '';

    if (isError) {
      const [errorKey] = Object.keys(this.input.errors ?? {});
      this.errorMessage = this.errors[errorKey] ?? this.defaultErrorMessages[errorKey] ?? '';

      const errorParams = this.input.errors[errorKey];
      if (_.isObject(errorParams)) {
        const pattern = /\${\s*(\w+?)\s*}/g;
        this.errorMessage = this.errorMessage.replace(pattern, (_, token) => {
          return errorParams[token] ?? '';
        });
      }
    }

    this.cdr.markForCheck();
  }

  // for better user experience waits for 200 ms
  showErrorMessageWithTimeout (isError: boolean, ms: number = 200): void {
    setTimeout(() => {
      this.showErrorMessage(isError);
    }, ms);
  }

  lineBreaks (str: string): string {
    return str.replace(/\\n/g, '<br>');
  }
}
