<mat-checkbox
  color="primary"
  (change)="onStateChange($event.checked)"
>
  <div
    class="terms-text"
    [innerHTML]="consentStatement.termsText | safeHtml"
  >
  </div>
</mat-checkbox>
<div
  *ngIf="isError"
  class="error-message"
  role="alert"
>
  Your consent is required.
</div>
