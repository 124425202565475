import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogsModule } from './modules/dialogs/dialogs.module';

// --- Providers
import { interceptorsProviders } from './interceptors/interseptors.providers';
import { tokensProviders } from './tokens/tokens.providers';
import { dialogsProviders } from './modules/dialogs/dialogs.providers';

// --- Services
import { SvgIconRegistry } from './services/svg-icon-registry.service';
import { EventHelperService } from './services/event-helper.service';
import { SharedHelperService } from './services/shared-helper.service';
import { EventService } from './services/event.service';
import { FullEventService } from './services/full-event.service';
import { EventDatetimeService } from './services/event-datetime.service';
import { SpeedtestService } from './services/speedtest.service';
import { MobileService } from './services/mobile.service';
import { DetectBrowserService } from './services/detect-browser.service';
import { StateStore } from './state-store';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    DialogsModule,
  ],
  providers: [
    interceptorsProviders,
    tokensProviders,
    dialogsProviders,
    SvgIconRegistry,
    EventHelperService,
    SharedHelperService,
    EventService,
    FullEventService,
    EventDatetimeService,
    SpeedtestService,
    MobileService,
    DetectBrowserService,
    StateStore,
  ],
})
export class CoreModule {
}
