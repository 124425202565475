import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UrlInterceptor } from './url.interceptor';
import { HttpNotFoundInterceptor } from './http-not-found.interceptor';

/**
 * Http interceptor providers in outside-in order
 */
export const interceptorsProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpNotFoundInterceptor, multi: true },
];
