import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/tokens';
import { Platform } from '@angular/cdk/platform';
import { ANDROID_APP_LINK, IOS_APP_LINK, UNIVERSAL_LINK_PATH } from '@shared/constants';

@Injectable()
export class MobileService {
  constructor (
    public platform: Platform,
    @Inject(WINDOW) private window: Window,
  ) { }

  /**
   * Returns true if the device where the app is running
   * is a mobile phone
   *
   * @return {boolean}
   */
  isMobile (): boolean {
    return this.isIOS() || this.isAndroid() || this.isIPadOS();
  }

  /**
   * Returns true for Android devices
   *
   * @return {boolean}
   */
  isAndroid (): boolean {
    return this.platform.ANDROID;
  }

  /**
   * Returns true if the device is an iPhone
   *
   * @return {boolean}
   */
  isIOS (): boolean {
    return this.platform.IOS;
  }

  /**
   * Returns true if the device is an iPad
   *
   * @return {boolean}
   */
  isIPadOS (): boolean {
    return navigator.platform?.toLowerCase().includes('mac') && this.isTouchable();
  }

  /**
   * Returns true if the device where the app is running
   * has a touchscreen
   *
   * @return {boolean}
   */
  isTouchable (): boolean {
    return navigator.maxTouchPoints > 1;
  }

  /**
   * Detects the device and tries to launch the mobile application
   *
   * @param {string} eventSlug
   * @param {string} eventToken
   * @return {void}
   */
  mobileAppLaunch (eventSlug: string, eventToken?: string): void {
    if (this.isIOS() || this.isIPadOS()) {
      return this.iOSAppLaunch(eventSlug, eventToken);
    }
    if (this.isAndroid()) {
      return this.androidAppLaunch(eventSlug, eventToken);
    }
  }

  /**
   * Tries to launch the mobile application
   * otherwise goes to the App Store
   *
   * @param {string} eventSlug
   * @param {string} eventToken
   * @return {void}
   */
  iOSAppLaunch (eventSlug: string, eventToken?: string): void {
    const url = 'https://' + this.getAppUrl(eventSlug, eventToken);

    const openedWindow = this.window.open(url);
    if (_.isNil(openedWindow)) {
      return;
    }

    // if openedWindow not null it's mean Shindig app didn't open
    openedWindow.close();
    const time = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - time > 1000) {
        return;
      }
      this.window.location.href = IOS_APP_LINK;
    }, 800);
  }

  /**
   * Tries to launch the mobile application
   * otherwise goes to the Google Play app store
   *
   * @param {string} eventSlug
   * @param {string} eventToken
   * @return {void}
   */
  androidAppLaunch (eventSlug: string, eventToken?: string): void {
    const url = this.getAppUrl(eventSlug, eventToken);
    const fallbackUrl = `S.browser_fallback_url=${encodeURIComponent(ANDROID_APP_LINK)}`;

    this.window.open(`intent://${url}#Intent;package=com.shindig;scheme=https;${fallbackUrl};end;`);
  }

  private getAppUrl (eventSlug: string, eventToken?: string): string {
    let url = `${UNIVERSAL_LINK_PATH}/${eventSlug}`;
    if (!_.isNil(eventToken)) {
      url += `?token=${eventToken}`;
    }
    return url;
  }
}
