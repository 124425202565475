import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertComponent, ConfirmComponent } from './components';
import { SharedModule } from '@shared/shared.module';


@NgModule({
  declarations: [
    AlertComponent,
    ConfirmComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    SharedModule,
  ],
  exports: [
    AlertComponent,
    ConfirmComponent,
  ],
})
export class DialogsModule { }
