import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SvgIcon } from '@assets/svg';

@Injectable({
  providedIn: 'root',
})
export class SvgIconRegistry {
  private registry = new Map<string, string>();

  constructor (
    @Inject(DOCUMENT) private document: Document,
  ) { }

  registerIcons (icons: SvgIcon[]): void {
    icons.forEach((icon: SvgIcon) => {return this.registry.set(icon.name, icon.data);});
  }

  getIcon (iconName: string): string | undefined {
    return this.registry.get(iconName);
  }

  getIconElement (iconName: string): SVGElement {
    const svgContent = this.getIcon(iconName);
    if (!svgContent) {
      throw Error(`There is not found the Icon with name ${iconName}, did you add it to the Icon registry?`);
    }
    return this.toElement(svgContent);
  }

  private toElement (content: string): SVGElement {
    const div = this.document.createElement('div');
    div.innerHTML = content;
    return div.querySelector('svg') as SVGElement;
  }
}
