// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import { SpeedTest } from '@shared/enums';
import { SpeedtestData, SpeedtestRawData } from '@shared/interfaces';

declare const Speedtest: any;

/**
 * Service Worker monkey patching to change
 * the 'speedtest_worker.js' server location storage
 */
const originalWorker = window.Worker;
const originalStartFn = Speedtest.prototype.start;
Speedtest.prototype.start = function () {
  class WorkerWrapper extends window.Worker {
    constructor (workerUrl: string, options?: WorkerOptions) {
      super(`assets/scripts/${workerUrl}`, options);
    }
  }

  window.Worker = WorkerWrapper;
  originalStartFn.call(this);
  window.Worker = originalWorker;
};

@Injectable()
export class SpeedtestService {
  private speedtest: any;

  private speedTestPromise: Promise<SpeedtestRawData>;

  async getData (): Promise<SpeedtestData> {
    if (_.isNil(this.speedTestPromise)) {
      await this.startTest();
    }

    const rawData = await this.speedTestPromise;
    const uploadSpeed = parseFloat(rawData.ulStatus) * 1024 / 8; // transfers from Mbit/s kB/s
    const downloadSpeed = parseFloat(rawData.dlStatus) * 1024 / 8; // transfers from Mbit/s kB/s

    return {
      quality: this.getSpeedQuality({ downloadSpeed, uploadSpeed }),
      uploadBandwidth: uploadSpeed,
      downloadBandwidth: downloadSpeed,
    };
  }

  async startTest (): Promise<SpeedtestRawData> {
    // create speedtest object
    if (_.isNil(this.speedtest)) {
      this.speedtest = new Speedtest();
    }

    // check the state
    if (this.speedtest.getState() === SpeedTest.SpeedtestState.testRunning) {
      return this.speedTestPromise;
    }

    // custom settings
    this.speedtest.setParameter('test_order', 'D_U'); // we only want download and upload test
    this.speedtest.setParameter('time_dl_max', 3); // 3 seconds for the download test (default 15 sec)
    this.speedtest.setParameter('time_ul_max', 3); // 3 seconds for the upload test (default 15 sec)
    this.speedtest.setParameter('time_dlGraceTime', 1.0);
    this.speedtest.setParameter('time_ulGraceTime', 0.5);

    this.speedtest.setSelectedServer({
      name: 'Shindig Speedtest',
      server: environment.testSpeedUrl,
      dlURL: 'backend/garbage.php',
      ulURL: 'backend/empty.php',
      pingURL: 'backend/empty.php',
      getIpURL: 'backend/getIP.php',
    });

    this.speedTestPromise = new Promise((resolve) => {
      const rawData: SpeedtestRawData[] = [];
      this.speedtest.onupdate = (data: SpeedtestRawData) => {
        rawData.push(data);
      };

      this.speedtest.onend = (isAborted: boolean) => {
        resolve(rawData.pop());
      };

      this.speedtest.start();
    });

    return this.speedTestPromise;
  }

  /**
   * Generates mock data for autotests
   *
   * @return {Promise<SpeedtestRawData>}
   */
  async startMockTest (): Promise<SpeedtestRawData> {
    this.speedTestPromise = Promise.resolve({
      testState: SpeedTest.SpeedtestState.done as number,
      dlStatus: '25.29',
      ulStatus: '47.86',
      pingStatus: '',
      clientIp: '',
      jitterStatus: '',
      dlProgress: 1,
      ulProgress: 1,
      pingProgress: 0,
      testId: null,
    });
    return this.speedTestPromise;
  }

  private getSpeedQuality (
    { downloadSpeed, uploadSpeed }: { downloadSpeed: number, uploadSpeed: number },
  ): SpeedTest.SpeedQuality {
    const high = {
      downloadSpeed: 300,
      uploadSpeed: 2000,
    };
    const low = {
      downloadSpeed: 160,
      uploadSpeed: 1000,
    };

    if (downloadSpeed >= high.downloadSpeed && uploadSpeed >= high.uploadSpeed) {
        return SpeedTest.SpeedQuality.high;
    }

    if (downloadSpeed >= low.downloadSpeed && uploadSpeed >= low.uploadSpeed) {
        return SpeedTest.SpeedQuality.low;
    }

    return SpeedTest.SpeedQuality.none;
  }
}
