<h1 mat-dialog-title>
  This event must be purchased as part of Current Series Events
</h1>

<mat-dialog-actions>
  <button
    type="button"
    class="btn-confirm"
    (click)="confirm()"
  >
    Buy Series Pass
  </button>

  <button
    type="button"
    class="btn-cancel"
    [routerLink]="['/series/' + data.eventSeriesSlug]"
    mat-dialog-close
  >
    View Full Program
  </button>
</mat-dialog-actions>

<mat-dialog-content>
  If you have already purchased a Series Pass and believe you are receiving this in error please double check your confirmation email for the name and email and how to access the event.
</mat-dialog-content>
