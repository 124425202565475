import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { createErrorHandler, TraceService } from '@sentry/angular';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';

// Components
import { AppComponent } from './app.component';

// Resources
import * as Resources from '@resources';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
  ],
  providers: [
    Resources.LegacyEventResourceStore,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        return () => {}; //NOSONAR
      },
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {}
