import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MobileService } from '@core/services/mobile.service';

export interface MobileAppDialogData {
  eventSlug: string;
  eventToken?: string;
}

@Component({
  selector: 'sd-open-mobile-app-dialog',
  templateUrl: './open-mobile-app-dialog.component.html',
  styleUrls: ['./open-mobile-app-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenMobileAppDialogComponent {
  isIOS = this.mobileService.isIOS();
  isIPadOS = this.mobileService.isIPadOS();
  isAndroid = this.mobileService.isAndroid();

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: MobileAppDialogData,
    private mdDialogRef: MatDialogRef<OpenMobileAppDialogComponent>,
    private mobileService: MobileService,
  ) { }

  iOSAppLaunch (): void {
    this.mobileService.iOSAppLaunch(this.data.eventSlug, this.data.eventToken);
    this.mdDialogRef.close();
  }

  androidAppLaunch (): void {
    this.mobileService.androidAppLaunch(this.data.eventSlug, this.data.eventToken);
    this.mdDialogRef.close();
  }
}
