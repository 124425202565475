import { BotInfo, BrowserInfo, detect, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo } from 'detect-browser';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
import { APIResult, SupportedBrowsers } from '@shared/interfaces';

@Injectable()
export class DetectBrowserService {
  private supportedBrowsers: Promise<SupportedBrowsers>;

  constructor (
    private http: HttpClient,
  ) { }

  /**
   * Attempts to detect a browser vendor and version (in a semver compatible format).
   *
   * @return {BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null}
   */
  detect (userAgent?: string): BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null {
    return detect(userAgent);
  }

  /**
   * Returns an object of supported browsers versions.
   *
   * @return {Promise<SupportedBrowsers>}
   */
  async getSupportedBrowsers (): Promise<SupportedBrowsers> {
    if (_.isNil(this.supportedBrowsers)) {
      this.supportedBrowsers = this.http
        .get<APIResult<SupportedBrowsers>>('/api/v3/options/supported-browsers')
        .pipe(pluck('result'))
        .toPromise();
    }
    return this.supportedBrowsers;
  }

  /**
   * Checks if the current browser is supported.
   *
   * @return {Promise<boolean>}
   */
  async isBrowserSupported (): Promise<boolean> {
    const browserInfo = this.detect();

    if (_.isNil(browserInfo)) {
      return false;
    }

    const configurableSupportedBrowsers = await this.getSupportedBrowsers();
    const supportedBrowsers = {
      opera: 50,
      ...configurableSupportedBrowsers,
    };

    const browserName = browserInfo.name.includes('edge') ? 'edge' : browserInfo.name;
    const minSupportedVersion = supportedBrowsers[browserName] ?? Infinity;
    const [versionMajor] = browserInfo.version.split('.');

    return parseInt(versionMajor, 10) >= minSupportedVersion;
  }
}
